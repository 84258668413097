<template>
  <div class="bodyheight">
    <v-container class="container-custom">
      <div class="paymentscreen">
        <v-progress-circular
          color="primary"
          indeterminate
          v-if="loading"
        ></v-progress-circular>
        <h2>{{ titleMessage }}</h2>
      </div>
      <div v-if="allPaymentToken" class="paymentscreen">
        <ul>
          <li>
            Payment has been
            <strong style="text-transform: lowercase">{{
              allPaymentToken.results.data.Status
            }}</strong>
          </li>
          <li>
            Amount:
            <strong>Rs.{{ allPaymentToken.results.data.Amount }}</strong>
          </li>
          <li>
            Service Charage:
            <strong>Rs.{{ allPaymentToken.results.data.ServiceCharge }}</strong>
          </li>
          <li>
            Bank:
            <strong>{{ allPaymentToken.results.data.Institution }}</strong>
          </li>
          <li>
            Exchange Rate:
            <strong>Rs.{{ allPaymentToken.results.data.ExchangeRate }}</strong>
          </li>
          <li>
            Remark:
            <strong>{{
              allPaymentToken.results.data.TransactionRemarks
            }}</strong>
          </li>
          <li>
            Transaction Date:
            <strong>
              {{
                allPaymentToken.results.data.TransactionDate
                  | moment("YYYY-MM-D hh:mm:ss a")
              }}</strong
            >
          </li>
        </ul>
      </div>

      <v-snackbar v-model="snackbar" :timeout="3000">{{ message }}</v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ImePaymentScreen",
  data() {
    return {
      valid: false,
      TokenId: "",
      MerchantCode: "",
      RefId: "",
      TranAmount: "",
      Method: "",
      RespUrl: "",
      CancelUrl: "",
      loading: true,
      snackbar: false,
      message: "",
      titleMessage: "You payment is processing. Please Wait !",
    };
  },

  methods: {
    ...mapActions([
      "getNepalPayment",
      "sendEnquiry",
      "geImePayment",
      "resetState",
      "geImePaymentStatus",
    ]),
    submitPayment() {
      var path = "https://payment.imepay.com.np:7979/WebCheckout/Checkout";
      let data = {
        TokenId: this.TokenId,
        MerchantCode: "NECOPVT",
        RefId: this.RefId,
        TranAmount: this.TranAmount,
        Method: "GET",
        RespUrl: "https://necojobs.com.np/paymentprocessime?status=true",
        CancelUrl: "https://necojobs.com.np/paymentprocessime?cancel=true",
      };

      var params = data;
      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },
  },
  computed: {
    ...mapGetters([
      "allNepalPayment",
      "allPaymentToken",
      "allImePaymentToken",
      "allImePaymentStatus",
    ]),
  },
  mounted() {
    console.log("Payment Screen");
  },
  async created() {
    this.resetState();
    if (this.$route.query.status) {
      this.titleMessage =
        "Your payment has been successfully processed. Please wait as we finalize the transaction";

      let successUrlDecode = this.$route.query.status.split("?")[1];
      successUrlDecode = successUrlDecode.split("=")[1];

      await this.geImePaymentStatus({
        txnId: atob(successUrlDecode).split("|")[4],
        tokenId: atob(successUrlDecode).split("|")[6],
        transactionId: atob(successUrlDecode).split("|")[3],
        msisDn: atob(successUrlDecode).split("|")[2],
      });

      if (this.allImePaymentStatus) {
        setTimeout(() => {
          if (this.allImePaymentStatus.slug !== "subscriptions") {
            this.$router.push(`/course/${this.allImePaymentStatus.slug}`);
          } else {
            this.$router.push(`/videocourses`);
          }
        }, 3000);
        this.snackbar = true;
        this.message = "Payment Successful! We have received your payment .";
      }
    } else if (this.$route.query.slug) {
      this.amount = this.$route.query.amount;
      await this.geImePayment({
        amount: this.$route.query.amount,
        fullName: this.$route.query.fullName,
        email: this.$route.query.email,
        phone: this.$route.query.phone,
        subject: this.$route.query.subject,
        trainingId: this.$route.query.trainingId,
        slug: this.$route.query.slug,
      });
      if (this.allImePaymentToken.status == 200 && this.allImePaymentToken.data) {
        this.TokenId = this.allImePaymentToken.data.results.TokenId;
        this.RefId = this.allImePaymentToken.data.results.RefId;
        this.TranAmount = this.allImePaymentToken.data.results.Amount;
        setTimeout(() => {
          this.submitPayment();
          this.loading = false;
        }, 2000);
      } else {
        this.snackbar = true;
        this.message = "You have already paid for this course.";
        this.titleMessage =
          "You have already paid for this course." +
          " Please wait..." +
          " we are redirecting you to the course page.";
        this.loading = false;
        setTimeout(() => {
          if (this.$route.query.slug !== "subscriptions") {
            this.$router.push(`/course/${this.$route.query.slug}`);
          } else {
            this.$router.push(`/videocourses`);
          }
        }, 3000);
      }
    } else if (this.$route.query.cancel) {
      this.snackbar = true;
      this.message =
        "Payment Cancelled! We were unable to process your payment. Please try again.";
      this.titleMessage =
        "Payment Cancelled! We were unable to process your payment. Please try again.";
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.bodyheight {
  height: 90vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.paymentscreen {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  padding: 25px 0;
  background-color: #eeeeee;
  ul {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 45px;
    line-height: 25px;
  }
  h4 {
    padding-bottom: 15px;
    font-size: 25px;
  }
}
</style>
